<template>
  <svg class="icon-delete" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M12.3976 20.0005C11.3136 20.0005 10.2567 19.9885 9.21112 19.9669C7.8735 19.9405 6.94789 19.0733 6.79668 17.7036C6.54468 15.4316 6.11347 10.0763 6.10947 10.0227C6.08227 9.69232 6.32868 9.40272 6.65908 9.37631C6.98469 9.36751 7.27909 9.59632 7.30549 9.92592C7.30949 9.98032 7.7399 15.3172 7.9895 17.5716C8.0751 18.35 8.49511 18.7517 9.23592 18.7669C11.236 18.8093 13.2768 18.8117 15.4768 18.7717C16.264 18.7565 16.6896 18.3628 16.7776 17.566C17.0256 15.3308 17.4576 9.98032 17.4624 9.92592C17.4888 9.59632 17.7808 9.36591 18.1081 9.37631C18.4385 9.40352 18.6849 9.69232 18.6585 10.0227C18.6537 10.0771 18.2201 15.446 17.9705 17.698C17.8152 19.0957 16.892 19.9461 15.4984 19.9717C14.432 19.9901 13.4032 20.0005 12.3976 20.0005Z"
          fill="#BCC1C8"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M19.1666 7.99103H5.60001C5.2688 7.99103 5 7.72223 5 7.39102C5 7.05982 5.2688 6.79102 5.60001 6.79102H19.1666C19.4978 6.79102 19.7666 7.05982 19.7666 7.39102C19.7666 7.72223 19.4978 7.99103 19.1666 7.99103Z"
          fill="#BCC1C8"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.5528 7.99126C15.6424 7.99126 14.852 7.34245 14.6728 6.44964L14.4784 5.47682C14.4376 5.32882 14.2688 5.20002 14.0768 5.20002H10.6903C10.4983 5.20002 10.3295 5.32882 10.2807 5.51362L10.0943 6.44964C9.91589 7.34245 9.12468 7.99126 8.21427 7.99126C7.88306 7.99126 7.61426 7.72246 7.61426 7.39125C7.61426 7.06005 7.88306 6.79124 8.21427 6.79124C8.55507 6.79124 8.85108 6.54804 8.91828 6.21363L9.11268 5.24082C9.31028 4.49521 9.95589 4 10.6903 4H14.0768C14.8112 4 15.4568 4.49521 15.6464 5.20482L15.8496 6.21363C15.916 6.54804 16.212 6.79124 16.5528 6.79124C16.884 6.79124 17.1528 7.06005 17.1528 7.39125C17.1528 7.72246 16.884 7.99126 16.5528 7.99126Z"
          fill="#BCC1C8"/>
  </svg>
</template>

<script>
export default {
  name: "IconDelete"
}
</script>

<style lang="scss" scoped>
.icon-delete {
  &:hover path{
    fill: var(--red-main);
  }
}

</style>